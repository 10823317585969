import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
// import Header from './header'

import '../less/koti.less'

const Layout = ({ children, hideHeader, hideFooter, customClass }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'viewport',
              content:
                'width=device-width, initial-scale=1.0',
            },
            {
              name: 'description',
              content:
                'Koti re-defines the smart home experience for you. Choose from curated products that fit your lifestyle and we handle the rest. Our voice butler is perfectly incorporated in Google Assistant, Alexa and Siri.',
            },
            {
              name: 'keywords',
              content: 'koti voice assistant,koti 智能家居,voice assistant,語音助手,google assistant,amazon alexa,apple homekit,HKT smart home,Smart Home Fortress,小米智能家居',
            },
          ]}
          bodyAttributes={{
            class: 'bootstrap-fs-modal',
          }}
        >
          <html lang="en" />
          <link
            href="//fonts.googleapis.com/css?family=Open+Sans:200,300,400,400i,500,600,700"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="//cdnjs.cloudflare.com/ajax/libs/Swiper/4.3.5/css/swiper.min.css"
          />
        </Helmet>
        {hideHeader ? null : <Navbar />}
        <div className={`main-container ${customClass}`}>
          {children}
          {hideFooter ? null : <Footer />}
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
