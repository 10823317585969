import React from 'react'
import { Link } from 'gatsby'


export default class Navbar extends React.Component {
  // constructor(props) {
  //   super(props)
  // }

  getYear() {
    return new Date().getFullYear()
  }

  render() {
    return (
      <footer className="footer-1 space--sm text-center-xs bg--dark">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <img
                alt="koti"
                className="logo"
                src={require('../assets/img/logo-white.png')}
              />
              <span className="type--fine-print">
                © <span className="update-year">{this.getYear()}</span> koti
              </span>
              <Link to="/terms/" className="type--fine-print">
                Terms &amp; Conditions
              </Link>
              {/* <a className="type--fine-print" href="#">
                Legal
              </a> */}
            </div>
            <div className="col-sm-6 text-right text-center-xs">
              {/* <ul className="social-list list-inline list--hover">
                <li>
                  <a href="#">
                    <i className="socicon socicon-google icon icon--xs" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="socicon socicon-twitter icon icon--xs" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="socicon socicon-facebook icon icon--xs" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="socicon socicon-instagram icon icon--xs" />
                  </a>
                </li>
              </ul> */}
              {/* <a href="#" className="btn type--uppercase">
                <span className="btn__text">Contact Us</span>
              </a> */}
            </div>
          </div>
        </div>
      </footer>
    )
  }

  // render() {
  //   return (
  //     <footer className="space--sm footer-1 text-center-xs">
  //       <div className="container">
  //         <div className="row">
  //           <div className="col-sm-6">
  //             <ul className="list-inline list--hover">
  //               <li>
  //                 <a href="#">
  //                   <span>Our Company</span>
  //                 </a>
  //               </li>
  //               <li>
  //                 <a href="#">
  //                   <span>Locations</span>
  //                 </a>
  //               </li>
  //               <li>
  //                 <a href="#">
  //                   <span>Products</span>
  //                 </a>
  //               </li>
  //               <li>
  //                 <a href="#">
  //                   <span>Work With Us</span>
  //                 </a>
  //               </li>
  //             </ul>
  //           </div>
  //           <div className="col-sm-6 text-right text-center-xs">
  //             <ul className="social-list list-inline list--hover">
  //               <li>
  //                 <a href="#">
  //                   <i className="socicon socicon-google icon icon--xs" />
  //                 </a>
  //               </li>
  //               <li>
  //                 <a href="#">
  //                   <i className="socicon socicon-twitter icon icon--xs" />
  //                 </a>
  //               </li>
  //               <li>
  //                 <a href="#">
  //                   <i className="socicon socicon-facebook icon icon--xs" />
  //                 </a>
  //               </li>
  //               <li>
  //                 <a href="#">
  //                   <i className="socicon socicon-instagram icon icon--xs" />
  //                 </a>
  //               </li>
  //             </ul>
  //             <a href="#" className="btn type--uppercase">
  //               <span className="btn__text">Contact Us</span>
  //             </a>
  //           </div>
  //         </div>
  //         <div className="row">
  //           <div className="col-sm-12">
  //             <img
  //               alt="Image"
  //               className="logo"
  //               src={require('../assets/img/logo-dark.png')}
  //             />
  //             <span className="type--fine-print">
  //               © <span className="update-year" /> koti
  //             </span>
  //             <a className="type--fine-print" href="#">
  //               Privacy Policy
  //             </a>
  //             <a className="type--fine-print" href="#">
  //               Legal
  //             </a>
  //           </div>
  //         </div>
  //       </div>
  //     </footer>
  //   )
  // }
}
